import React, { Component, useContext } from "react";
import CartContext from "./CartContext";
import { OrderDetails } from "./OrderDetails";
import { OrderDetailsMobile } from "./OrderDetailsMobile";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import Fuse from "fuse.js";
import swal from "sweetalert";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadSpinner } from "./LoadSpinner";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useRef } from "react";
import { useState } from "react";
import { MessageBox } from "./MessageBox";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import {
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Modal,
  Form,
  Row,
  Col,
  FloatingLabel,
  Nav,
  Table,
  Navbar,
  Card,
} from "react-bootstrap";

import { ProductAddOrEditModal } from "./ProductAddOrEditModal";
import { EditProductModal } from "./ProductAddOrEditModal";

import Select from "react-select";
import {} from "react-bootstrap";

export class QuotationRequest extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ordersDetail: [],
      selectedOrder: -1,
      selectedOrderRow: "",
      filteredOrdersDetail: [],
      showOrderDetailsModal: false,
      loadSpinnerOpen: false,
      firstload: false,
      quotationTypes: [],
      refreshTab: false,
      ShowNewRequestTabRefresh: true,
      ShowQuotationTabRefresh: false,
      ShowPaymentTabRefresh: false,
      ShowProduceTabRefresh: false,
      ShowShipTabRefresh: false,
      ShowDeliveryTabRefresh: false,
      ShowHistoryTabRefresh: false,
    };
  }

  refreshTab = () => {
    this.setState({ refreshTab: true }, () => {
      this.setState({ refreshTab: false });
    });
  };

  ShowSelectedTab = (k) => {
    this.setState({ ShowNewRequestTabRefresh: false });
    this.setState({ ShowQuotationTabRefresh: false });
    this.setState({ ShowPaymentTabRefresh: false });
    this.setState({ ShowProduceTabRefresh: false });
    this.setState({ ShowShipTabRefresh: false });
    this.setState({ ShowDeliveryTabRefresh: false });
    this.setState({ ShowHistoryTabRefresh: false });

    if (k == "NewRequest") this.setState({ ShowNewRequestTabRefresh: true });
    if (k == "Quotation") this.setState({ ShowQuotationTabRefresh: true });
    if (k == "Payment") this.setState({ ShowPaymentTabRefresh: true });
    if (k == "Produce") this.setState({ ShowProduceTabRefresh: true });
    if (k == "Ship") this.setState({ ShowShipTabRefresh: true });
    if (k == "Delivery") this.setState({ ShowDeliveryTabRefresh: true });
    if (k == "History") this.setState({ ShowHistoryTabRefresh: true });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <>
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <div className="px-0 bg-white">
            <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
              <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                <Nav>
                  <h4 className=""> Quotation Workflow Management</h4>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                <Nav>
                  <ButtonToolbar className="text-start align-middle">
                    &nbsp;
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.refreshTab()}
                    >
                      <BiRefresh size="20"></BiRefresh>
                      Refresh
                    </Button>
                    &nbsp;
                  </ButtonToolbar>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={(k) => {
                this.setState({ key: k });
                this.ShowSelectedTab(k);
              }}
              className="mb-3 bg-light fw-bold"
              fill
              style={{ fontSize: "22px", color: "yellow" }}
            >
              <Tab eventKey="NewRequest" title="1.New Request">
                {this.state.ShowNewRequestTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="NewRequest"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Quotation" title="2.Quotation ">
                {this.state.ShowQuotationTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Quotation"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Payment" title="3.Payment">
                {this.state.ShowPaymentTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Payment"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Produce" title="4.Produce">
                {this.state.ShowProduceTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Produce"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Ship" title="5.Ship">
                {this.state.ShowShipTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Ship"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Delivery" title="6.Delivery">
                {this.state.ShowDeliveryTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Delivery"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="History" title="7.History">
                {this.state.ShowHistoryTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="History"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
            </Tabs>
          </div>
        </>
      </>
    );
  }
}

export class QuotationRequestTable extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ordersDetail: [],
      selectedOrder: -1,
      selectedOrderRow: "",
      filteredOrdersDetail: [],
      showOrderDetailsModal: false,
      loadSpinnerOpen: false,
      firstload: false,
      quotationTypes: [],
      users: [],
    };
  }

  GetUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreUserAccounts/" +
        this.context.storeId
      // config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            //users: data.filter((f) => f.storeRoleId != 1),
            users: data,
          });
        }
      })
      .catch((error) => {
        alert("Session Timeout! Signout and Signin again!");
      });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  Reload = () => {
    // this.setState({ RefreshPage: true }, () => {});
    this.GetOrders();
  };

  GetQuoteStatusTypes = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetQuoteStatusTypes/")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ quotationTypes: data });
      })
      .catch((error) => {});
  };

  GetStatusName = (id) => {
    var result = this.state.quotationTypes.filter((f) => f.id == id);
    if (result.length > 0) return result[0].statusName;
    return "";
  };

  GetOrders = () => {
    this.setState({ ordersDetail: [] }, () => {
      this.ShowLoadSpinner();
      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GetQuotationRequestsByState/" +
          this.context.storeId +
          "/" +
          this.props.CurrentState
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ ordersDetail: data });
          this.setState({ filteredOrdersDetail: data });
          this.HideLoadSpinner();
        })
        .catch((error) => {
          this.HideLoadSpinner();
        });
    });
  };

  OnOrderRowClick = (e, order) => {
    this.setState({ selectedOrder: order });
    this.setState({ showOrderDetailsModal: true });
  };
  ModalClose = () => {
    this.setState({ showOrderDetailsModal: false }, () => {});
    this.Reload();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ filteredOrdersDetail: this.state.ordersDetail }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.ordersDetail, {
      keys: ["quoteNumber", "deliveryAddress", "customerName", "contactNumber"],
      threshold: 0.0,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredOrdersDetail: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredOrdersDetail: [] });
    }
  };

  enableDeleteButtonMenu = () => {
    if (this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  OnDeleteOrder = (e, order) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteAnCustomerOrder/" +
        order.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.Reload();
          alert("not deleted");
          return;
        }
        this.Reload();
        Swal.fire({
          title: "Status",
          text: "Deleted Successfully",
          // confirmButtonText: "yes",

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("OnDelete Failed" + error);
      });
  };

  OnOrderRowSelect = (e, order) => {
    this.setState({ selectedOrderRow: "" }, () => {
      this.setState({ selectedOrderRow: order });
    });
  };

  // onSearchChange = (query) => {
  //   this.setState({ reload: false });
  //   this.setState({ searchquery: query });
  //   if (!query) {
  //     this.setState({ filteredOrdersDetail: this.state.ordersDetail }, () => {
  //       this.setState({ reload: true });
  //     });
  //     return;
  //   }
  //   const fuse = new Fuse(this.state.ordersDetail, {
  //     keys: ["orderId", "deliveryAddress", "customerName", "mobileNo"],
  //     threshold: 0.0,
  //     ignoreLocation: true,
  //     findAllMatches: true,
  //     includeMatches: true,
  //     distance: 120,
  //   });
  //   const result = fuse.search(query);
  //   const finalResult = [];
  //   if (result.length) {
  //     result.forEach((item) => {
  //       finalResult.push(item.item);
  //     });
  //     this.setState({ filteredOrdersDetail: finalResult.slice(0, 15) }, () => {
  //       this.setState({ reload: true });
  //     });
  //   } else {
  //     this.setState({ filteredOrdersDetail: [] });
  //   }
  // };

  GetDateDifference = (createddate) => {
    var startTime = new Date();
    var endTime = new Date(createddate);
    var diff = startTime - endTime;
    var minutes = Math.floor(diff / 1000 / 60);
    var hours = Math.floor(minutes / 60, 0);
    var days = Math.floor(hours / 24, 0);
    var daysstring = days > 0 ? days + "d : " : " ";
    var hoursstring = hours > 0 ? (hours % 24) + "h : " : " ";

    return daysstring + hoursstring + (minutes % 60) + " m";
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetOrders();
      this.GetQuoteStatusTypes();
      this.GetUsers();
    }
  }

  render() {
    return (
      <>
        <div>
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <div>
            <Stack direction="horizontal" gap={3} className="py-2">
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </b>
              <FormControl
                type="search"
                placeholder="Search by QuotationId / Phoneno / CustomerName... "
                className="text-primary fw-bold bg-white "
                aria-label="Search"
                onChange={(e) => this.onSearchChange(e.target.value)}
              />
            </Stack>
            <Table striped className="text-center">
              <thead style={{ fontSize: "16px" }}>
                <th width="5%">#</th>
                <th width="10%">Received On</th>
                <th width="10%" className="text-center">
                  Quotation ID
                </th>
                <th width="20%" className="text-center">
                  Customer
                </th>
                <th width="20%" className="text-center">
                  Purpose
                </th>
                <th width="30%" className="text-center">
                  Quotation
                </th>
                <th width="5%" className="text-center">
                  Options
                </th>
              </thead>
              <tbody className="text-center" style={{ fontSize: "14px" }}>
                {this.state.filteredOrdersDetail
                  .sort((a, b) => (a.created > b.created ? 1 : -1))
                  .map((order, i) => (
                    <>
                      <tr
                        className=" text-start border-2 border-secondary "
                        onDoubleClick={(e) => this.OnOrderRowClick(e, order)}
                        style={{ paddingTop: "50px" }}
                      >
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">
                          {this.context.GetInDateFormat(
                            new Date(order.created)
                          )}
                        </td>
                        <td className="text-center">
                          <h4>
                            <b>{order.quoteNumber}</b>
                          </h4>
                          AssignedTo:{" "}
                          <span className="CornersRounded border border-primary ">
                            &nbsp;{order.assignedTo}&nbsp;
                          </span>{" "}
                        </td>
                        <td className="border ">
                          <h5>
                            Name:
                            <b>{order.customerName}</b>
                          </h5>
                          <h5>
                            Contact: <b>{order.contactNumber}</b>
                          </h5>
                          <h5>
                            EmailId: <b>{order.emailId}</b>
                          </h5>
                        </td>
                        {/* <td className="border">
                          Category Name:{" "}
                          <span className="CornersRounded border border-dark">
                            &nbsp;{order.categoryName}&nbsp;
                          </span>{" "}
                          <br />
                          Product Name:
                          <b>
                            <span className="CornersRounded border border-dark bg-danger text-white">
                              &nbsp;{order.productName}&nbsp;
                            </span>{" "}
                          </b>
                          <br />
                          Detailed Requirement:{" "}
                          <b>{order.detailedRequirement}</b>
                          &nbsp;
                        </td>
                        <td className="border text-center">
                          <h4>
                            <span className="CornersRounded border border-dark bg-warning text-dark">
                              &nbsp;{order.qty}&nbsp;
                            </span>
                          </h4>
                        </td> */}
                        <td className="border">
                          <Row>
                            <Col>Purpose: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark">
                                &nbsp;{order.purposeOfProcurement} &nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col>Language: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark">
                                &nbsp;{order.preferredLanguage}&nbsp;
                              </span>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col>Timeline: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark">
                                &nbsp;{order.deliveryTimeline}&nbsp;
                              </span>
                            </Col>
                          </Row>
                        </td>

                        <td className="border ">
                          <Row className="m-0 p-0 mb-1">
                            <Col>Status:</Col>
                            <Col>
                              <span className="CornersRounded border border-dark bg-warning text-dark">
                                &nbsp; {this.GetStatusName(order.quoteStatus)}
                                &nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row className="m-0 p-0 mb-1">
                            <Col>Importance: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark ">
                                &nbsp;
                                {order.requestSeverityType == 1
                                  ? "HOT"
                                  : order.requestSeverityType == 2
                                  ? "WARM"
                                  : order.requestSeverityType == 3
                                  ? "COLD"
                                  : ""}
                                &nbsp;
                              </span>{" "}
                            </Col>
                          </Row>
                          <Row className="m-0 p-0 mb-1">
                            <Col>Total Quotation Amount: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark bg-primary text-white">
                                {/* &nbsp; {order.quotationTotalAmount}&nbsp; */}
                                {order.quoteItems.reduce(
                                  (a, v) => (a = a + Number(v.quoteAmount)),
                                  0
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row className="m-0 p-0 mb-1">
                            <Col>Total Amount Received:</Col>
                            <Col>
                              <span className="CornersRounded border border-dark bg-success text-white">
                                &nbsp;
                                {/* {order.amountReceived} */}
                                {order.quotePayments.reduce(
                                  (a, v) => (a = a + Number(v.amount)),
                                  0
                                )}
                                &nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row className="m-0 p-0 mb-1">
                            <Col>Payment Status: </Col>
                            <Col>
                              <b>
                                <span className="CornersRounded border border-danger ">
                                  &nbsp;
                                  {order.paymentStatus == 1
                                    ? "Paid"
                                    : order.paymentStatus == 2
                                    ? "UnPaid"
                                    : order.paymentStatus == 3
                                    ? "PartiallyPaid"
                                    : ""}
                                  &nbsp;
                                </span>
                              </b>
                            </Col>
                          </Row>
                        </td>

                        <td>
                          <Button
                            className="mx-2"
                            onClick={(e) => this.OnOrderRowClick(e, order)}
                          >
                            Open
                          </Button>

                          <Button
                            className="my-2"
                            hidden={this.enableDeleteButtonMenu()}
                            variant="danger"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  " Are you sure that you want to Delete?"
                                )
                              ) {
                                this.OnDeleteOrder(e, order);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </div>
          {this.state.showOrderDetailsModal === true ? (
            <>
              {window.innerWidth >= 1200 ? (
                <>
                  <QuotationRequestDetails
                    show={this.state.showOrderDetailsModal}
                    onHide={this.ModalClose}
                    orderDetail={this.state.selectedOrder}
                    quotationTypes={this.state.quotationTypes}
                    CurrentState={this.props.CurrentState}
                    users={this.state.users}
                  />
                </>
              ) : (
                <>
                  <QuotationRequestDetails
                    show={this.state.showOrderDetailsModal}
                    onHide={this.ModalClose}
                    orderDetail={this.state.selectedOrder}
                    quotationTypes={this.state.quotationTypes}
                    users={this.state.users}
                  />
                </>
              )}
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export class QuotationRequestDetails extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      orderDetail: this.props.orderDetail,
      quoteStatusNew: "",
      ShowPaymentModal: false,
      ShowNewRequirementModal: false,
      reloaded: false,
      users: [],
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };

  SaveOrder = () => {
    var tmporder = this.state.orderDetail;
    if (this.state.quoteStatusNew != "")
      tmporder.quoteStatus = this.state.quoteStatusNew;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmporder),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateQuationRequest/",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          alert("not updated");
          return;
        }
        // Swal.fire({
        //   title: "Status",
        //   text: "Saved Successfully",
        //   timer: 1000,
        // }).then((result) => {});
        this.ShowMessagBox("Status", "Saved Successfully!");
      })
      .catch((error) => {
        //this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.SaveOrder();
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "quoteStatusNew":
        this.setState({ quoteStatusNew: value });
        break;
      case "quotationTotalAmount":
        this.state.orderDetail.quotationTotalAmount = value;
        break;
      case "amountReceived":
        this.state.orderDetail.amountReceived = value;
        break;
      case "paymentStatus":
        this.state.orderDetail.paymentStatus = value;
        break;
      case "requestSeverityType":
        this.state.orderDetail.requestSeverityType = value;
        break;
      case "assignedTo":
        this.state.orderDetail.assignedTo = value;
        break;
      case "notes":
        this.state.orderDetail.notes = value;
        break;
    }
    this.setState({ orderDetail: this.state.orderDetail });
  };

  handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.orderDetail.quoteItems];
    list[index][name] = value;
    list[index]["quoteAmount"] =
      list[index]["costPerPiece"] * list[index]["qty"];
    this.setState({ orderDetail: this.state.orderDetail });
  };

  GetQuotationRequestById = () => {
    this.setState({ ordersDetail: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GetQuotationRequestById/" +
          this.state.orderDetail.id
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ orderDetail: data }, () => {
            this.setState({ reloaded: true }, () => {
              this.setState({ reloaded: false });
            });
          });
        })
        .catch((error) => {});
    });
  };

  OnDelete = (e, payment) => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteQuotePaymentById/" +
        payment.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.GetQuotationRequestById();
      })
      .catch((error) => {
        console.log("Exception");
      });
  };

  OnPaymentModalClose = () => {
    this.setState({ ShowPaymentModal: false }, () => {
      this.GetQuotationRequestById();
    });
  };

  OnNewRequirementModalClose = () => {
    this.setState({ ShowNewRequirementModal: false }, () => {
      this.GetQuotationRequestById();
    });
  };

  componentDidMount = () => {
    this.state.orderDetail.quoteItems = this.state.orderDetail.quoteItems.sort(
      (a, b) => (a.seqNo > b.seqNo ? 1 : -1)
    );
    this.setState({ orderDetail: this.state.orderDetail });
  };

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          backdrop="static"
          keyboard={false}
          dialogClassName="order-modal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <Form
            onSubmit={this.handleSubmit}
            style={{ fontFamily: "Verdana", fontSize: "14px" }}
          >
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Quotation Details
              </Modal.Title>
            </Modal.Header>
            {this.state.reloaded == false ? (
              <>
                <Modal.Body className="show-grid ">
                  <Row>
                    <Col>
                      <div className="p-1 border">
                        <h3 style={{ fontFamily: "Helvetica" }}>
                          <b>Customer Details</b>
                        </h3>
                        <Row>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Quotation Number"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.quoteNumber}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Request Created"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.context.GetInDateFormat(
                                  new Date(this.state.orderDetail.created)
                                )}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Customr Name"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.customerName}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contact Number"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.contactNumber}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Email-id"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.emailId}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Purpose"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={
                                  this.state.orderDetail.purposeOfProcurement
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Preferred Language"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.preferredLanguage}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Delivery Timeline"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.deliveryTimeline}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Address"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={
                                  this.state.orderDetail.address +
                                  ", " +
                                  this.state.orderDetail.landmark +
                                  ", " +
                                  this.state.orderDetail.city +
                                  ", " +
                                  this.state.orderDetail.state +
                                  ", " +
                                  this.state.orderDetail.country +
                                  ", " +
                                  this.state.orderDetail.pincode
                                }
                              />
                            </FloatingLabel>
                          </Col>

                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="GST Number"
                              className="mb-1"
                            >
                              <Form.Control
                                type="text"
                                disabled
                                value={this.state.orderDetail.taxNo}
                              />
                            </FloatingLabel>
                          </Col>
                          <Col>
                            Attachment File:&nbsp;&nbsp;
                            <Form.Label>
                              {this.state.orderDetail.attachmentFileName !=
                                undefined &&
                              this.state.orderDetail.attachmentFileName !=
                                "" ? (
                                <>
                                  <div className="border CornersRoundedSmall p-1">
                                    {this.state.orderDetail.attachmentFileName}{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <a
                                      href={
                                        this.context.store.storageBlobUrl +
                                        this.context.store
                                          .storageBlobContainerName +
                                        "/attachments/" +
                                        this.state.orderDetail
                                          .attachmentFileName +
                                        this.context.store.storageSasToken
                                      }
                                      target="_blank"
                                      className="text-green text-decoration-none"
                                    >
                                      View
                                    </a>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </Form.Label>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div className="p-1 border">
                        <h3 style={{ fontFamily: "Helvetica" }}>
                          <b>Quotation Status</b>
                        </h3>
                        <Row>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Current Status"
                              required
                              className="mb-1"
                            >
                              <Form.Select
                                type="text"
                                // name="quoteStatus"
                                size="lg"
                                disabled
                                value={this.state.orderDetail.quoteStatus}
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                {this.props.quotationTypes.map((m) => (
                                  <option value={m.id}>{m.statusName}</option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Next Status"
                              className="mb-1"
                            >
                              <Form.Select
                                type="text"
                                name="quoteStatusNew"
                                size="lg"
                                // required
                                value={this.state.quoteStatusNew}
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value=""></option>
                                {this.props.CurrentState == "NewRequest" ? (
                                  <>
                                    <option value={2}>
                                      Start Requirement Review
                                    </option>
                                    <option value={10}>Cancel Quotation</option>
                                  </>
                                ) : this.props.CurrentState == "Quotation" ? (
                                  <>
                                    <option value={3}>
                                      Quotation Complete and Send Email
                                    </option>
                                    <option value={10}>Cancel Quotation</option>
                                  </>
                                ) : this.props.CurrentState == "Payment" ? (
                                  <>
                                    <option value={4}>
                                      FullPaymentReceived
                                    </option>
                                    <option value={5}>
                                      PartialPaymentReceived
                                    </option>
                                    <option value={6}>
                                      Start Produce/Making
                                    </option>
                                    <option value={10}>
                                      Cancel Quotation Request
                                    </option>
                                  </>
                                ) : this.props.CurrentState == "Produce" ? (
                                  <>
                                    <option value={7}>
                                      Produce Complete & Ready To Ship
                                    </option>
                                    <option value={10}>
                                      Refunded Amount & Cancel Order
                                    </option>
                                  </>
                                ) : this.props.CurrentState == "Ship" ? (
                                  <>
                                    <option value={8}>
                                      Fully Paid And Sent For Delivery
                                    </option>
                                    <option value={10}>
                                      Refunded Amount & Cancel Order
                                    </option>
                                  </>
                                ) : this.props.CurrentState == "Delivery" ? (
                                  <>
                                    <option value={9}>
                                      Delivery and Closed
                                    </option>
                                  </>
                                ) : this.props.CurrentState == "History" ? (
                                  <></>
                                ) : (
                                  <></>
                                )}
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Payment Status"
                              required
                              className="mb-1"
                            >
                              <Form.Select
                                type="text"
                                name="paymentStatus"
                                value={this.state.orderDetail.paymentStatus}
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value={1}>Paid</option>
                                <option value={2}>UnPaid</option>
                                <option value={3}>PartiallyPaid</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Severity"
                              required
                              className="mb-1"
                            >
                              <Form.Select
                                type="text"
                                required
                                name="requestSeverityType"
                                value={
                                  this.state.orderDetail.requestSeverityType
                                }
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value=""></option>
                                <option value={1}>HOT</option>
                                <option value={2}>WARM</option>
                                <option value={3}>COLD</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="AssignTo"
                              required
                              className="mb-1"
                            >
                              {/* <Form.Control
                                type="text"
                                name="assignedTo"
                                required
                                value={this.state.orderDetail.assignedTo}
                                onChange={(e) => this.handleInputChange(e)}
                              ></Form.Control> */}
                              <Form.Select
                                type="text"
                                size="lg"
                                className="pd-2 w-100"
                                name="assignedTo"
                                required
                                value={this.state.orderDetail.assignedTo}
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value=""></option>
                                {this.props.users.map((d) => (
                                  <>
                                    {d.emailId == "productsupport" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <option value={d.id}>
                                          {d.emailId}
                                        </option>
                                      </>
                                    )}
                                  </>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <a
                              href={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/invoice/" +
                                this.state.orderDetail.quoteNumber +
                                ".html" +
                                this.context.store.storageSasToken +
                                "&xyz=" +
                                new Date().toLocaleString()
                              }
                              target="_blank"
                            >
                              Download Invoice
                            </a>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Notes:
                      <Form.Control
                        as="textarea"
                        name="notes"
                        type="text"
                        rows={5}
                        maxLength={1999}
                        value={this.state.orderDetail.notes}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div className="border">
                        <Row>
                          <Col>
                            <h3 style={{ fontFamily: "Helvetica" }}>
                              <b>Requirements & Quotation</b>
                            </h3>
                          </Col>

                          <Col className="text-end">
                            <Button
                              onClick={(e) =>
                                this.setState({ ShowNewRequirementModal: true })
                              }
                            >
                              +Add
                            </Button>
                          </Col>
                        </Row>
                        <Table className="text-center">
                          <thead className="bg-light">
                            <th width="5%">#</th>
                            <th width="10%">Category</th>
                            <th width="10%">Product/Item Name</th>
                            <th width="30%">Detail Requirement</th>
                            <th width="5%">Seq.No</th>
                            <th width="10%">Cost Per Piece</th>
                            <th width="10%">Quantity</th>
                            <th width="10%">Quote Tot.Amount</th>
                            <th width="5%"></th>
                          </thead>
                          <tbody>
                            {this.state.orderDetail.quoteItems.map(
                              (order, i) => (
                                <tr className="">
                                  <td>{i + 1}</td>
                                  <td>{order.categoryName}</td>
                                  <td>{order.productName}</td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      as="textarea"
                                      rows={2}
                                      name="detailedRequirement"
                                      size="lg"
                                      value={order.detailedRequirement}
                                      onChange={(e) => this.handleChange(e, i)}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      className="text-center"
                                      type="number"
                                      name="seqNo"
                                      size="lg"
                                      value={order.seqNo}
                                      onChange={(e) => this.handleChange(e, i)}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      className="text-center"
                                      type="number"
                                      name="costPerPiece"
                                      size="lg"
                                      value={order.costPerPiece}
                                      onChange={(e) => this.handleChange(e, i)}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      className="text-center"
                                      type="number"
                                      name="qty"
                                      size="lg"
                                      value={order.qty}
                                      onChange={(e) => this.handleChange(e, i)}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      className="text-center"
                                      name="quoteAmount"
                                      size="lg"
                                      readOnly
                                      value={order.quoteAmount}
                                      onChange={(e) => this.handleChange(e, i)}
                                    />
                                  </td>
                                  <td>
                                    {order.categoryName == undefined ||
                                    order.categoryName == "" ? (
                                      <>
                                        <OverlayTrigger
                                          overlay={<Tooltip>Delete!</Tooltip>}
                                        >
                                          <Button
                                            className="mx-1 text-danger border-0"
                                            style={{
                                              backgroundColor: "transparent",
                                            }}
                                            onClick={(e) => {
                                              if (
                                                window.confirm(
                                                  "Are you sure you want to Delete ?"
                                                )
                                              ) {
                                                // this.OnDelete(e, payment);
                                              }
                                            }}
                                          >
                                            <FaTrashAlt size={24} />
                                          </Button>
                                        </OverlayTrigger>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col className="text-end">
                      <h3>
                        Total :
                        {Number(
                          this.state.orderDetail.quoteItems.reduce(
                            (a, v) => (a = a + Number(v.quoteAmount)),
                            0
                          )
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h3>
                    </Col>
                    <Col className="text-end">
                      <h3>
                        Tax(18%) :
                        {Number(
                          this.state.orderDetail.quoteItems.reduce(
                            (a, v) => (a = a + Number(v.quoteAmount)),
                            0
                          ) * 0.18
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h3>
                    </Col>
                    <Col className="text-end">
                      <h3>
                        Grand Total :
                        {Number(
                          this.state.orderDetail.quoteItems.reduce(
                            (a, v) => (a = a + Number(v.quoteAmount)),
                            0
                          ) +
                            this.state.orderDetail.quoteItems.reduce(
                              (a, v) => (a = a + Number(v.quoteAmount)),
                              0
                            ) *
                              0.18
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col className="border p-1">
                      <Row>
                        <Col>
                          <h3 style={{ fontFamily: "Helvetica" }}>
                            <b>Payment History</b>
                          </h3>
                        </Col>
                        <Col className="text-end">
                          <Button
                            onClick={(e) =>
                              this.setState({ ShowPaymentModal: true })
                            }
                          >
                            +Add
                          </Button>
                        </Col>
                      </Row>
                      <Table
                        className="bg-light CornersRounded"
                        style={{
                          fontSize: "14px",
                          // letterSpacing: "3px",
                          fontFamily: "Raleway",
                        }}
                      >
                        <thead>
                          <th>Received</th>
                          <th>TransactionID</th>
                          <th>Amount</th>
                          <th></th>
                        </thead>
                        <tbody>
                          {this.state.orderDetail.quotePayments
                            .sort((a, b) => (a.created > b.created ? 1 : -1))
                            .map((payment, i) => (
                              <tr>
                                <td>
                                  {this.context.GetInDateFormat(
                                    new Date(payment.created)
                                  )}
                                </td>
                                <td>{payment.paymentTransactionId}</td>
                                <td>
                                  {Number(payment.amount).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </td>
                                <td>
                                  <td>
                                    {payment.paymentMethod == 2 ? (
                                      <>
                                        <OverlayTrigger
                                          overlay={<Tooltip>Delete!</Tooltip>}
                                        >
                                          <Button
                                            className="mx-1 text-danger border-0"
                                            style={{
                                              backgroundColor: "transparent",
                                            }}
                                            onClick={(e) => {
                                              if (
                                                window.confirm(
                                                  "Are you sure you want to Delete ?"
                                                )
                                              ) {
                                                this.OnDelete(e, payment);
                                              }
                                            }}
                                          >
                                            <FaTrashAlt size={24} />
                                          </Button>
                                        </OverlayTrigger>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td></td>
                            <td>
                              <b>Total Paid:</b>
                            </td>
                            <td>
                              <b>
                                {this.state.orderDetail.quotePayments
                                  .reduce(
                                    (a, v) => (a = a + Number(v.amount)),
                                    0
                                  )
                                  .toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  {this.state.ShowPaymentModal == true ? (
                    <>
                      <AddPaymentModal
                        orderDetail={this.state.orderDetail}
                        OnClose={this.OnPaymentModalClose}
                      ></AddPaymentModal>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.ShowNewRequirementModal == true ? (
                    <>
                      <AddNewRequirementsModal
                        orderDetail={this.state.orderDetail}
                        OnClose={this.OnNewRequirementModalClose}
                      ></AddNewRequirementsModal>
                    </>
                  ) : (
                    <></>
                  )}
                </Modal.Body>
              </>
            ) : (
              <></>
            )}

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" type="submit" size="lg">
                  Save
                </Button>
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export class AddPaymentModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      created: new Date(),
      transactionID: "",
      amount: 0,
    };
  }

  SubmitQuotePayment = () => {
    var tCreated =
      new Date(this.state.created).getMonth() +
      1 +
      "/" +
      new Date(this.state.created).getDate() +
      "/" +
      new Date(this.state.created).getFullYear();

    var str = {
      created: tCreated,
      quoteId: this.props.orderDetail.id,
      paymentTransactionId: this.state.transactionID,
      amount: this.state.amount,
      paymentMethod: 2, //offline
    };
    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitQuotePayment", str)
      .then((response) => {
        if (response.status === 200) {
          console.log("Success");
          this.props.OnClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  handleSubmit = (e) => {
    if (this.state.transactionID.length != "" && this.state.amount > 0) {
      this.SubmitQuotePayment();
    } else
      Swal.fire({
        title: "Warning",
        text: "Invalid Inputs",
        confirmButtonText: "Ok",
      }).then((result) => {});
  };

  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="SM"
            backdrop="static"
            keyboard={false}
            // dialogClassName="order-modal"
            show={true}
          >
            <Form
              // onSubmit={this.handleSubmit}
              style={{ fontFamily: "Verdana", fontSize: "14px" }}
            >
              <Modal.Header closeButton className="bg-warning">
                <Modal.Title id="contained-modal-title-vcenter">
                  Add Payment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid ">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    format="dd-MMM-yy"
                    onChange={(e) => {
                      this.setState({ created: e }, () => {});
                    }}
                    value={this.state.created}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Transaction Ref</Form.Label>
                  <Form.Control
                    type="text"
                    name="transactionID"
                    maxLength={63}
                    value={this.state.transactionID}
                    onChange={(e) =>
                      this.setState({ transactionID: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    value={this.state.amount}
                    onChange={(e) => this.setState({ amount: e.target.value })}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="bg-light">
                <Form.Group>
                  <Button
                    variant="primary"
                    //type="submit"
                    onClick={(e) => this.handleSubmit(e)}
                    size="lg"
                  >
                    Save
                  </Button>
                </Form.Group>
                <Button variant="danger" size="lg" onClick={this.props.OnClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}

export class AddNewRequirementsModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      costPerPiece: 0,
      qty: 1,
    };
  }

  SubmitAddQuoteItem = () => {
    var str = {
      quoteId: this.props.orderDetail.id,
      productName: this.state.productName,
      costPerPiece: this.state.costPerPiece,
      qty: this.state.qty,
      quoteAmount: this.state.costPerPiece * this.state.qty,
      seqNo: this.props.orderDetail.quoteItems.length + 1,
    };
    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitNewQuoteItem", str)
      .then((response) => {
        if (response.status === 200) {
          console.log("Success");
          this.props.OnClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  handleSubmit = (e) => {
    if (
      this.state.productName.length != "" &&
      this.state.costPerPiece > 0 &&
      this.state.qty > 0
    ) {
      this.SubmitAddQuoteItem();
    } else
      Swal.fire({
        title: "Warning",
        text: "Invalid Inputs",
        confirmButtonText: "Ok",
      }).then((result) => {});
  };

  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="SM"
            backdrop="static"
            keyboard={false}
            // dialogClassName="order-modal"
            show={true}
          >
            <Form
              // onSubmit={this.handleSubmit}
              style={{ fontFamily: "Verdana", fontSize: "14px" }}
            >
              <Modal.Header closeButton className="bg-warning">
                <Modal.Title id="contained-modal-title-vcenter">
                  Add Product or Other Charges
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid ">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Product / Item </Form.Label>
                  <Form.Control
                    type="text"
                    name="productName"
                    maxLength={63}
                    value={this.state.productName}
                    required
                    onChange={(e) =>
                      this.setState({ productName: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Cost</Form.Label>
                  <Form.Control
                    type="number"
                    name="costPerPiece"
                    required
                    value={this.state.costPerPiece}
                    onChange={(e) =>
                      this.setState({ costPerPiece: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    type="number"
                    name="qty"
                    required
                    value={this.state.qty}
                    onChange={(e) => this.setState({ qty: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Total: </Form.Label>
                  <b>{this.state.costPerPiece * this.state.qty}</b>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="bg-light">
                <Form.Group>
                  <Button
                    variant="primary"
                    //type="submit"
                    onClick={(e) => this.handleSubmit(e)}
                    size="lg"
                  >
                    Save
                  </Button>
                </Form.Group>
                <Button variant="danger" size="lg" onClick={this.props.OnClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}
